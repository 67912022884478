@tailwind utilities;

@layer utilities {
  .custom-scrollbar {
    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 30px;
      @apply bg-gray-300;
    }
  }
}
